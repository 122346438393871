import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../agent';
import {
  isApiError,
} from '../../common/utils';

export const discourseLogin = createAsyncThunk( "discourse/discourselogin",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/api/forum/register', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const discourseRegister = createAsyncThunk( "discourse/discourseRegister",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/forum/register', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

export const discourseCheckUser = createAsyncThunk( "discourse/discourseCheckUser",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.get('/forum/check-user');
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);


const initialState = {
  status: "idle",
  user: [],
  error: null,
  getDiscourseRegisterResponse: [],
  getDiscourseCheckUserResponse: {},
};

const discourseSlice = createSlice({
  name: 'discourse',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle"
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(discourseRegister.pending, (state) => {
      state.status = "discourseRegister/loading";
    })
    .addCase(discourseRegister.fulfilled, (state, action) => {
      state.status = "discourseRegister/success";
      state.getDiscourseRegisterResponse = action.payload;
    })
    .addCase(discourseRegister.rejected, (state, action) => {
      state.status = "discourseRegister/failed";
      state.error = action.payload;
    });

    builder
    .addCase(discourseCheckUser.pending, (state) => {
      state.status = "discourseCheckUser/loading";
    })
    .addCase(discourseCheckUser.fulfilled, (state, action) => {
      state.status = "discourseCheckUser/success";
      state.getDiscourseCheckUserResponse = action.payload;
    })
    .addCase(discourseCheckUser.rejected, (state, action) => {
      state.status = "discourseCheckUser/failed";
      state.error = action.payload;
    });
  },
});

export const { resetStatus } = discourseSlice.actions;

export const loadingDiscourseStatus = (state) => state.discourse.status

export const getDiscourseRegisterResponse = (state) => state.discourse.getDiscourseRegisterResponse
export const getDiscourseCheckUserResponse = (state) => state.discourse.getDiscourseCheckUserResponse
export const DiscourseError = (state) => state.discourse.error 


export default discourseSlice.reducer;
