import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import config from './config';
import './assets/scss/style.scss';

import TagManager from 'react-gtm-module'

if( process.env.REACT_APP_ENV === 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-TF99FW'
    }

    TagManager.initialize(tagManagerArgs)
}

const app = (<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>);
// ReactDOM.render(app, document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render(app)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
