import Config from '../config';
import axios from 'axios';
/* eslint-disable import/no-anonymous-default-export */
const API_ROOT=process.env.REACT_APP_BACKEND_API ?? 'https://api-customerportal.viewqwest.com/api';
  // process.env.REACT_APP_BACKEND_URL ?? 'https://api-customerportal.viewqwest.com/api';
  
  // https://api-vqcp.vqbn.com/public/api
  // https://demo.vqbn.com/backend-customer-portal/public/api

/**
 * Serialize object to URL params
 *
 * @param {Record<string, unknown>} object
 * @returns {String}
 */

function serialize(object) {
  const params = [];

  for (const param in object) {
    if (Object.hasOwnProperty.call(object, param) && object[param] != null) {
      params.push(`${param}=${encodeURIComponent(object[param])}`);
    }
  }

  return params.join('&');
}

let token = null;

/**
 *
 * @typedef {Object} ApiError
 * @property {{[property: string]: string}} errors
 */

/**
 * @typedef  {Object} UserAuth
 * @property {Object} user
 * @property {String} user.account_no
 * @property {String} user.email
 * @property {String} user.location
 * @property {String} user.type
 * @property {String} user.token
 *
 * @typedef  {Object}  Profile
 * @property {String}  username
 * @property {String}  bio
 * @property {String}  image
 * @property {Boolean} following
 *
 * @typedef  {Object}   Tags
 * @property {String[]} tags
 *
 * @typedef  {Object}   Article
 * @property {String}   title
 * @property {String}   slug
 * @property {String}   body
 * @property {String}   description
 * @property {String[]} tagList
 * @property {Profile}  author
 * @property {Boolean}  favorited
 * @property {Number}   favoritesCount
 * @property {String}   createdAt
 * @property {String}   updatedAt
 *
 * @typedef  {Object}  ArticleResponse
 * @property {Article} article
 *
 * @typedef  {Object}    ArticlesResponse
 * @property {Article[]} articles
 * @property {Number}    articlesCount
 *
 * @typedef  {Object}  Comment
 * @property {String}  id
 * @property {String}  body
 * @property {Profile} author
 * @property {String}  createdAt
 * @property {String}  updatedAt
 *
 * @typedef  {Object}  CommentResponse
 * @property {Comment} comment
 *
 * @typedef  {Object}    CommentsResponse
 * @property {Comment[]} comments
 *
 * @typedef  {Object}  ProfileResponse
 * @property {Profile} profile
 */

/**
 * API client
 *
 * @param {String} url The endpoint
 * @param {Object} body The request's body
 * @param {('GET'|'DELETE'|'PUT'|'POST')} [method='GET'] The request's method
 *
 * @throws {@link ApiError API Error}
 *
 * @returns {Promise<Object>} API response's body
 */
const agent = async (url, body, method = 'GET') => {
  const headers = {}

  // console.log(token);

  if (body && !url.includes('http')) {
    headers['Content-Type'] = 'application/json'
  }

  if (token && !url.includes('http')) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['Access-Control-Allow-Origin'] = "*";
    headers['Access-Control-Allow-Headers'] = "*";
  }

  let result;

  try {

    const response = await axios({
      url: `${url.includes('http') ? '' : API_ROOT}${url}`,
      method,
      data: body ? body : {},
      headers
    });

    result = response.data

    // console.log('r1', response)

    if(!result?.error && response.status !== 200){
      result = { errors: { [response.status]: [response.statusText], code: response.status, data: result } };
    }

    return result;

  } catch (error) {


    // console.log('axios_response', error.response);
    // console.log('axios_header', error.headers);
    // console.log('axios_config', error.config);

    const isLoginPage = window.location.href.toString().includes("/login");
    if (error.response?.statusText === "Unauthorized" && !isLoginPage) {
      localStorage.removeItem('jwt')
      const ipparams = window.localStorage.getItem('ipparams');
      window.location.href = `${Config.basename}/${ipparams? ipparams : 'sg'}/login`;
    }

    if(error?.response?.data) {
      result = { errors: { [error.response.status]: [error.response.statusText], code: error.response.status, data: error.response.data } };
    } 

    // console.log('r2', error.toJSON())   

    throw result.errors.data;
  }
};

const requests = {
  /**
   * Send a DELETE request
   *
   * @param {String} url The endpoint
   * @returns {Promise<Object>}
   */
  del: (url) => agent(url, undefined, 'DELETE'),
  /**
   * Send a GET request
   *
   * @param {String} url The endpoint
   * @param {Object} [query={}] URL parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]
   * @param {String} [query.author]
   * @param {String} [query.tag]
   * @param {String} [query.favorited]
   * @returns {Promise<Object>}
   */
  get: (url, query = {}) => {
    if (Number.isSafeInteger(query?.page)) {
      query.limit = query.limit ? query.limit : 10;
      query.offset = query.page * query.limit;
    }
    delete query.page;
    const isEmptyQuery = query == null || Object.keys(query).length === 0;

    return agent(isEmptyQuery ? url : `${url}?${serialize(query)}`);
  },
  /**
   * Send a PUT request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  put: (url, body) => agent(url, body, 'PUT'),
  /**
   * Send a POST request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  post: (url, body) => agent(url, body, 'POST'),
};

const Auth = {
  current: () => requests.get('/userDetails'),
  getMenu: () => requests.get('/dynamic-menu'),
  /**
   * Login with email and password
   *
   * @param {String} account_no
   * @param {String} email
   * @param {String} location
   * @param {String} type
   * @returns {Promise<UserAuth>}
   */
  login: (account_no, email, location = 'sg', type) =>
    requests.post('/magic-link', { account_no: account_no, email: email, location: location, type: type, cp_version: 'react'}),
  /**
   * Register with username, email and password
   *
   * @param {String} username
   * @param {String} email
   * @param {String} password
   * @returns {Promise<UserAuth>}
   */
  // register: (username, email, password) =>
  //   requests.post('/users', { user: { username, email, password } }),
  /**
   * Update user
   *
   * @param {Object}  user
   * @param {String} [user.email]
   * @param {String} [user.username]
   * @param {String} [user.bio]
   * @param {String} [user.image]
   * @param {String} [user.password]
   * @returns {Promise<UserAuth>}
   */
  save: (user) => requests.put('/user', { user }),
  magicLogin: (query) => requests.get(`/magic-login?${query}`),
  ipLocate: () => requests.get(`/ip-location`),
  postFeedback: (data) => requests.post('/feedback', data),
  superLogin: (data) => requests.post('/super-magic-link', data),
  registerUser: (data) => requests.post('/registerUser', data)
};

const Tags = {
  /**
   * Get all tags
   *
   * @returns {Promise<Tags>}
   */
  getAll: () => requests.get('/tags'),
};

const Subscriptions = {
  /**
   * Get all subscriptions
   *
   */
  all: (data) => requests.get(`/subscriptions/get-sub`, {
    sub_id: data.sub_id ? data.sub_id : null, 
    sub_no: data.sub_no ? data.sub_no : null, 
    pagination: data.pagination ? data.pagination : null, 
    next_page: data.next_page? data.next_page: null}),
  list: () => requests.get(`/subscriptions/get-sub-list`),
  /**
   * Get all articles from author
   *
   * @param {String} author Article's author
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  byAuthor: (author, page) =>
    requests.get(`/articles`, { author, limit: 5, page }),
  /**
   * Get all articles by tag
   *
   * @param {String} tag Article's tag
   * @param {Number} page
   * @returns {Promise<ArticlesResponse>}
   */
  byTag: (tag, page) => requests.get(`/articles`, { tag, page }),
  /**
   * Remove one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<{}>}
   */
  del: (slug) => requests.del(`/articles/${slug}`),
  /**
   * Favorite one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  favorite: (slug) => requests.post(`/articles/${slug}/favorite`),
  /**
   * Get article favorited by author
   *
   * @param {String} username Username
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  favoritedBy: (username, page) =>
    requests.get(`/articles`, { favorited: username, limit: 5, page }),
  /**
   * Get all articles in the user's feed
   *
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  feed: (page) => requests.get('/articles/feed', { page }),
  /**
   * Get one article by slug
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  get: (slug) => requests.get(`/articles/${slug}`),
  /**
   * Unfavorite one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  unfavorite: (slug) => requests.del(`/articles/${slug}/favorite`),
  /**
   * Update one article
   *
   * @param {Partial<Article>} article
   * @returns {Promise<ArticleResponse>}
   */
  update: ({ slug, ...article }) =>
    requests.put(`/articles/${slug}`, { article }),
  /**
   * Create a new article
   *
   * @param {Object}   article
   * @param {String}   article.title
   * @param {String}   article.description
   * @param {String}   article.body
   * @param {String[]} article.tagList
   * @returns {Promise<ArticleResponse>}
   */
  create: (article) => requests.post('/articles', { article }),
};

const Invoices = {
  get: () =>
    requests.get(`/get-invoices`),
};

const getQR = {
  get: () =>
    requests.get(`/get-qr`),
};

const Dashboard = {
  get: (params) =>
    requests.get(`/dashboard`, params),
};

const Paymentmethod = {
  get: () =>
    requests.get(`/paymentmethod`),
};

const updateCard = {
  post: (paymentMethod) =>
    requests.post(`/update-paymentmethod/${paymentMethod.id}`, paymentMethod),
};

const makePrimary = {
  post: (paymentId) =>
    requests.post(`/primary-paymentmethod/${paymentId}`),
};

const addPaymentMethod = {
  post: (paymentId) =>
    requests.post(`/add-paymentmethod`, { payment_method_id: paymentId }),
};

const makeCardPayment = {
  post: (paymentMethod) =>
    requests.post(`/make-card-payment/${paymentMethod.id}`, paymentMethod),
};

const makePaymentMethod = {
  post: (paymentId) =>
    requests.post(`/make-paymentmethod/${paymentId}`),
};

const generatePaymentSignature = {
  post: (paymentId) =>
    requests.post(`/generate-zuora-signature`, paymentId),
};

const deletePaymentmethod = {
  post: (paymentId) =>
    requests.post(`/delete-paymentmethod/${paymentId}`),
};

const primaryPaymentMethod = {
  post: ({ paymentId, newcard }) =>
    requests.post(`/primary-paymentmethod/${paymentId}`, { newcard }),
};

const terminateFibernet = {
  post: (contract) =>
    requests.post(`/terminate-fibernet`, contract),
};

const getOutstandingBalance = {
  get: (method) =>
    requests.get(`/getAccountBalance`, method),
};

const subscriptionRelocation = {
  update: (data) => requests.post(`/relocation`, data),
};

const getVasDetails = {
  get: (data) => requests.get(`/subscriptions/get-vas-details`, data),
};

const getSecurenet = {
  get: (Case_ID) => requests.get(`/securenet/get-sg?case_id=${Case_ID}`),
};

const getScheduler = {
  get: () => requests.get(`/securenet/get-scheduler`),
};

const getList = {
  get: (Case_ID) => requests.get(`/securenet/sg-all?case_id=${Case_ID}`),
};

const updateSchedule = {
  post: (data) => requests.post(`/securenet/update-securenet`, data),
};

const updateSG = {
  post: (data) => requests.post(`/securenet/update-sg`, data),
};

const subscribeVas = {
  post: (data) => requests.post(`/subscribe-vas/${data.type}`, data),
};

const unsubscribeVas = {
  post: (data) => requests.post(`/unsubscribe-vas/${data.type}`, data),
};

const vasJob = {
  post: (id) => requests.post(`/vas-jobs/all/${id}`)
}

const subscriptionTemporarySuspension = {
  update: (data) => requests.post(`/temporary-suspension`, data),
}

const subscriptionTransferOfOwnership = {
  update: (data) => requests.post(`/transfer-ownership`, data),
  verifyToken: (data) => requests.get(`/check-too-expiration`, data),
  verify: (data) => requests.post(`/verifyTransferOwnership/${data.token}`, data)
}

const Profile = {
  update: (data) => requests.post(`/updateUser`, data),
  get: () => requests.get(`/getUser`),
  verifyNewEmail: (id) => requests.get(`/verifyNewEmail/${id}`)
};

const Support = {
  getTickets: () => requests.get('/open-cases'),
  newEnquiry: (data) => requests.post('/new-enquiry', data), 
}

const myInfo = {
  getMyInfo: (data) => requests.post(`/get-myinfo`, data),
  getData: () => requests.post(''),
}

const SGLocate = {
  get: (data) => requests.get(`/postal/${data}`)
}

const calendarHolidayDates = {
  get: () => requests.get('/get-holidays')
}

export default {
  subscriptionRelocation,
  subscriptionTemporarySuspension,
  subscriptionTransferOfOwnership,
  Subscriptions,
  Auth,
  Profile,
  Invoices,
  getQR,
  Dashboard,
  Paymentmethod,
  makePrimary,
  addPaymentMethod,
  makeCardPayment,
  makePaymentMethod,
  deletePaymentmethod,
  primaryPaymentMethod,
  generatePaymentSignature,
  getOutstandingBalance,
  getVasDetails,
  Tags,
  updateCard,
  subscribeVas,
  unsubscribeVas,
  vasJob,
  terminateFibernet,
  getSecurenet,
  getScheduler,
  getList,
  updateSchedule,
  updateSG,
  setToken: (_token) => {
    token = _token;
  },
  Support,
  myInfo,
  SGLocate,
  calendarHolidayDates,
  requests
};
