import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../agent';
import { isApiError } from '../../common/utils';
import _ from 'lodash';
import axios from 'axios';

export const getPlans = createAsyncThunk( "speedboostpromo/getPlans",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/speedboost/get-plans', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const signUpMeta = createAsyncThunk( "speedboostpromo/signUpMeta",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/speedboost/signup-meta', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const orderSummary = createAsyncThunk( "speedboostpromo/orderSummary",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/speedboost/fetch-order-summary', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const submitSelectedPlan = createAsyncThunk( "speedboostpromo/submitSelectedPlan",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post('/speedboost/submit-selected-plan', data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  getPlansResponse: {},
  signUpMetaResponse: {},
  orderSummaryResponse: {},
  submitSelectedPlanResponse: {},
}

const speedBoostPromoSlice = createSlice({
  name: 'speedboostpromo',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle"
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    // getPlans
    builder
    .addCase(getPlans.pending, (state) => {
      state.status = "getPlans/loading";
    })
    .addCase(getPlans.fulfilled, (state, action) => {
      state.status = "getPlans/success";
      state.getPlansResponse = action.payload;
    })
    .addCase(getPlans.rejected, (state, action) => {
      state.status = "getPlans/failed";
      state.error = action.payload;
    });

    // signUpMeta
    builder
    .addCase(signUpMeta.pending, (state) => {
      state.status = "signUpMeta/loading";
    })
    .addCase(signUpMeta.fulfilled, (state, action) => {
      state.status = "signUpMeta/success";
      state.signUpMetaResponse = action.payload;
    })
    .addCase(signUpMeta.rejected, (state, action) => {
      state.status = "signUpMeta/failed";
      state.error = action.payload;
    });

    // orderSummary
    builder
    .addCase(orderSummary.pending, (state) => {
      state.status = "orderSummary/loading";
    })
    .addCase(orderSummary.fulfilled, (state, action) => {
      state.status = "orderSummary/success";
      state.orderSummaryResponse = action.payload;
    })
    .addCase(orderSummary.rejected, (state, action) => {
      state.status = "orderSummary/failed";
      state.error = action.payload;
    });

    // submitSelectedPlan
    builder
    .addCase(submitSelectedPlan.pending, (state) => {
      state.status = "submitSelectedPlan/loading";
    })
    .addCase(submitSelectedPlan.fulfilled, (state, action) => {
      state.status = "submitSelectedPlan/success";
      state.submitSelectedPlanResponse = action.payload;
    })
    .addCase(submitSelectedPlan.rejected, (state, action) => {
      state.status = "submitSelectedPlan/failed";
      state.error = action.payload;
    });
  }
})

export const { resetStatus } = speedBoostPromoSlice.actions;

export const loadingSpeedboostPromoStatus = (state) => state.speedboostpromo.status

export const getPlansResponse = (state) => state.speedboostpromo.getPlansResponse
export const getSignUpMetaResponse = (state) => state.speedboostpromo.signUpMetaResponse
export const getOrderSummaryResponse = (state) => state.speedboostpromo.orderSummaryResponse
export const getSubmitSelectedPlanResponse = (state) => state.speedboostpromo.submitSelectedPlanResponse
export const SpeedBoostError = (state) => state.speedboostpromo.error 

export default speedBoostPromoSlice.reducer;