import * as React from 'react';
import ROLES from '../src/App/constants/roles';

const DashboardPage = React.lazy(() => import('./Pages/Dashboard/DashboardPage'));
const SubscriptionPage = React.lazy(() => import('./Pages/Subscription/SubscriptionPage'));
const RelocationPage = React.lazy(() => import('./Pages/Relocation/RelocationPage'));
const TempSuspensionPage = React.lazy(() => import('./Pages/TempSuspension/TempSuspensionPage'));
const TransferOfOwnershipPage = React.lazy(() => import('./Pages/TransferOfOwnership/TransferOfOwnershipPage'));
const ManageSubscriptionPage = React.lazy(() => import('./Pages/ManageSubscription/ManageSubscriptionPage'))
const CreditCardsPage = React.lazy(() => import('./Pages/CreditCards/CreditCardsPage'));
const Addcard = React.lazy(() => import('./Pages/AddCard/AddCard'));
const EditCreditCard = React.lazy(() => import('./Pages/EditCreditCard/EditCreditCard'));
const MyEditCreditCard = React.lazy(() => import('./Pages/MyEditCreditCard/MyEditCreditCard'));
const ExternalPage = React.lazy(() => import('./Pages/Externals/ExternalPage'));
const ProfilePage = React.lazy(() => import('./Pages/Profile/ProfilePage'));
const InvoicePage = React.lazy(() => import('./Pages/Invoice/InvoicePage'));
const InvoicePayment = React.lazy(() => import('./Pages/Invoice/InvoicePayment'));
const OtherSamplePage = React.lazy(() => import('./Pages/Other/SamplePage'));
const SupportEnquiryPage = React.lazy(() => import('./Pages/SupportEnquiry/SupportEnquiryPage'));
const NewEnquiryPage = React.lazy(() => import('./Pages/NewEnquiry/NewEnquiryPage'));
// const VerificationPage = React.lazy(() => import('./Pages/Verification/VerificationPage'));
const SpeedboostPage = React.lazy(() => import('./Pages/Externals/SpeedboostPage'));
const SpeedboostPageSubmitSuccess = React.lazy(() => import('./Pages/Externals/SpeedboostSubmitSuccess'));
const SpeedboostPageSubmitError = React.lazy(() => import('./Pages/Externals/SpeedboostSubmitError'));
const DiscoursePage = React.lazy(() => import('./Pages/DiscoursePage/DiscoursePage'));
const DiscourseStart = React.lazy(() => import('./Pages/DiscoursePage/DiscourseStart'));
const PendingSubscriptionPage = React.lazy(() => import('./Pages/Subscription/PendingSubscriptionPage'));
const ManagePendingSubscriptionPage = React.lazy(() => import('./Pages/ManagePendingSubscription/ManagePendingSubscriptionPage'));
const AppointmentPage = React.lazy(() => import('./Pages/ManagePendingSubscription/AppointmentPage'));
const SupportTickets = React.lazy(() => import('./Pages/SupportTickets/SupportTickets'));
const SupportTicketAppointment = React.lazy(() => import('./Pages/SupportTickets/SupportTicketAppointment'));

const routes = [
    // sg
    { path: '/sg/dashboard/', role: null, exact: true, name: 'Dashboard Page', component: DashboardPage },
    { path: '/sg/subscriptions', role: 'cc7f62f7ee29419ffe1f8618adca3036', exact: true, name: 'Subscriptions', component: SubscriptionPage },
    { path: '/sg/subscriptions/manage/:id/', role: 'cc7f62f7ee29419ffe1f8618adca3036', exact: true, name: 'Manage Subscription', component: ManageSubscriptionPage },
    { path: '/sg/subscriptions/manage/:id/temporary-suspension/', role: 'cc7f62f7ee29419ffe1f8618adca3036', exact: true, name: 'Temporary Suspension', component: TempSuspensionPage },
    { path: '/sg/subscriptions/manage/:id/transfer-of-ownership/', role: 'cc7f62f7ee29419ffe1f8618adca3036', exact: true, name: 'Transfer Of Ownership', component: TransferOfOwnershipPage },
    { path: '/sg/subscriptions/manage/:id/relocation/', role: 'cc7f62f7ee29419ffe1f8618adca3036', exact: true, name: 'Relocation', component: RelocationPage },
    { path: '/sg/credit-cards/', role: null, exact: true, name: 'Credit Cards', component: CreditCardsPage },
    { path: '/sg/credit-cards/add-card/', role: null, exact: true, name: 'Add Card', component: Addcard },
    { path: '/sg/credit-cards/edit-card/:id', role: null, exact: true, name: 'Edit Credit Card', component: EditCreditCard },
    { path: '/sg/profile', role: null, exact: true, name: 'Profile Page', component: ProfilePage },
    { path: '/sg/invoice', role: null, exact: true, name: 'Invoice Page', component: InvoicePage },
    { path: '/sg/invoice/payment', role: null, exact: true, name: 'Invoice Page', component: InvoicePayment },
    { path: '/sg/sample-page', role: ROLES.VDPL, exact: true, name: 'Sample Page', component: OtherSamplePage },
    { path: '/sg/promo',role: 'e82e89f0bb71f67f1b45c5900a855dcc', exact: true, name: 'Promo Page', component: ExternalPage },
    { path: '/sg/support/support-enquiry/', role: '2524e8b6d524c24e8d6246a3f1b4a99a', exact: true, name: 'Support Enquiry Page', component: SupportEnquiryPage },
    { path: '/sg/support/support-enquiry/new-enquiry/', role: '2524e8b6d524c24e8d6246a3f1b4a99a', exact: true, name: 'New Support Page', component: NewEnquiryPage },
    { path: '/sg/promo/speedboost',role: '13e302a8c2ec75ab7567c6cf48970113', exact: true, name: 'Speedboost', component: SpeedboostPage }, //13e302a8c2ec75ab7567c6cf48970113| e82e89f0bb71f67f1b45c5900a855dcc 
    { path: '/sg/promo/speedboost/submit-success',role: '13e302a8c2ec75ab7567c6cf48970113', exact: true, name: 'Speedboost Submit Success', component: SpeedboostPageSubmitSuccess },
    { path: '/sg/promo/speedboost/submit-error',role: '13e302a8c2ec75ab7567c6cf48970113', exact: true, name: 'Speedboost Submit Error', component: SpeedboostPageSubmitError },
    { path: '/sg/community-forum',role: null, exact: true, name: 'Community Forum', component: DiscoursePage },
    { path: '/discourse-start',role: null, exact: true, name: 'DiscourseStart', component: DiscourseStart },

    { path: '/sg/subscriptions/pending', role: '4b5407c2d37e3f5f161dc88e776950ed', exact: true, name: 'Pending Subscriptions', component: PendingSubscriptionPage },
    { path: '/sg/subscriptions/pending/manage/:subid', role: '4b5407c2d37e3f5f161dc88e776950ed', exact: true, name: 'Manage Pending Subscription', component: ManagePendingSubscriptionPage },
    { path: '/sg/subscriptions/pending/manage/:subid/appointment', role: '4b5407c2d37e3f5f161dc88e776950ed', exact: true, name: 'Appointment Slot', component: AppointmentPage },
    { path: '/sg/support/support-tickets', role: 'e5fba2750321271b6d5025e754f0f065', exact: true, name: 'Support Tickets', component: SupportTickets },
    { path: '/sg/support/support-ticket/:ttid', role: 'e5fba2750321271b6d5025e754f0f065', exact: true, name: 'Appointment Slot', component: SupportTickets },
    { path: '/sg/support/support-ticket/:ttid/appointment', role: 'e5fba2750321271b6d5025e754f0f065', exact: true, name: 'Appointment Slot', component: SupportTicketAppointment },
    // my
    { path: '/my/dashboard/', role: null, exact: true, name: 'Dashboard Page', component: DashboardPage },
    { path: '/my/subscriptions', role: '2352ea39444ec719e01e92d5377d3183', exact: true, name: 'Subscriptions', component: SubscriptionPage },
    { path: '/my/subscriptions/manage/:id/', role: '2352ea39444ec719e01e92d5377d3183', exact: true, name: 'Manage Subscription', component: ManageSubscriptionPage },
    { path: '/my/subscriptions/manage/:id/temporary-suspension/', role: '2352ea39444ec719e01e92d5377d3183', exact: true, name: 'Temporary Suspension', component: TempSuspensionPage },
    { path: '/my/subscriptions/manage/:id/transfer-of-ownership/', role: '2352ea39444ec719e01e92d5377d3183', exact: true, name: 'Transfer Of Ownership', component: TransferOfOwnershipPage },
    { path: '/my/subscriptions/manage/:id/relocation/', role: '2352ea39444ec719e01e92d5377d3183', exact: true, name: 'Relocation', component: RelocationPage },
    { path: '/my/credit-cards/', role: null, exact: true, name: 'Credit Cards', component: CreditCardsPage },
    { path: '/my/credit-cards/add-card/', role: null, exact: true, name: 'Add Card', component: Addcard },
    { path: '/my/credit-cards/edit-card/:id', role: null, exact: true, name: 'Edit Credit Card', component: MyEditCreditCard },
    { path: '/my/profile', role: null, exact: true, name: 'Profile Page', component: ProfilePage },
    { path: '/my/invoice', role: null, exact: true, name: 'Invoice Page', component: InvoicePage },
    { path: '/my/invoice/payment', role: null, exact: true, name: 'Invoice Page', component: InvoicePayment },
    { path: '/my/sample-page', role: ROLES.VDPL, exact: true, name: 'Sample Page', component: OtherSamplePage },
    { path: '/my/promo',role: 'e82e89f0bb71f67f1b45c5900a855dcc', exact: true, name: 'Promo Page', component: ExternalPage },
    { path: '/my/support/support-enquiry/', role: '2524e8b6d524c24e8d6246a3f1b4a99a', exact: true, name: 'Support Enquiry Page', component: SupportEnquiryPage },
    { path: '/my/support/support-enquiry/new-enquiry/', role: '2524e8b6d524c24e8d6246a3f1b4a99a', exact: true, name: 'New Support Page', component: NewEnquiryPage },
    { path: '/my/community-forum',role: null, exact: true, name: 'Community Forum', component: DiscoursePage },
];
export default routes;
