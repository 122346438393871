import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../agent';
import { isApiError } from '../../common/utils';
import _ from 'lodash';
import axios from 'axios';

export const getSupportTicket = createAsyncThunk( "supportticket/getSupportTicket",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.get('/support-tickets', data);
      return response;
    } catch (error) {
      //if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      //}
      //throw error;
    }
  }
);

export const getSupportTicketSchedule = createAsyncThunk( "supportticket/getSupportTicketSchedule",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.get('/tt-schedule', data);
      return response;
    } catch (error) {
      //if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      //}
      //throw error;
    }
  }
);

export const confirmBookingTT = createAsyncThunk( "supportticket/confirmBookingTT",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post(
        '/confirm-booking-tt', data);
      return response;
    } catch (error) {
      // if (isApiError(error)) {
      return thunkApi.rejectWithValue(error);
      // }
      //throw error;
    }
  }
);

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  getSupportTicketResponse: {},
  getSupportTicketScheduleResponse: {},
  confirmBookingTTResponse: {},
}

const supportticketSlice = createSlice({
  name: 'supportticket',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle"
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    // getSupportTicket
    builder
    .addCase(getSupportTicket.pending, (state) => {
      state.status = "getSupportTicket/loading";
    })
    .addCase(getSupportTicket.fulfilled, (state, action) => {
      state.status = "getSupportTicket/success";
      state.getSupportTicketResponse = action.payload;
    })
    .addCase(getSupportTicket.rejected, (state, action) => {
      state.status = "getSupportTicket/failed";
      state.error = action.payload;
    });

    // getSupportTicketSchedule
    builder
    .addCase(getSupportTicketSchedule.pending, (state) => {
      state.status = "getSupportTicketSchedule/loading";
    })
    .addCase(getSupportTicketSchedule.fulfilled, (state, action) => {
      state.status = "getSupportTicketSchedule/success";
      state.getSupportTicketScheduleResponse = action.payload;
    })
    .addCase(getSupportTicketSchedule.rejected, (state, action) => {
      state.status = "getSupportTicketSchedule/failed";
      state.error = action.payload;
    });

    // confirmBookingTT
    builder
    .addCase(confirmBookingTT.pending, (state) => {
      state.status = "confirmBookingTT/loading";
    })
    .addCase(confirmBookingTT.fulfilled, (state, action) => {
      state.status = "confirmBookingTT/success";
      state.confirmBookingTTResponse = action.payload;
    })
    .addCase(confirmBookingTT.rejected, (state, action) => {
      state.status = "confirmBookingTT/failed";
      state.error = action.payload;
    });
  }
})

export const { resetStatus } = supportticketSlice.actions;

export const loadingsupportticketStatus = (state) => state.supportticket.status

export const getSupportTicketResponse = (state) => state.supportticket.getSupportTicketResponse
export const getSupportTicketScheduleResponse = (state) => state.supportticket.getSupportTicketScheduleResponse
export const getconfirmBookingTTResponse = (state) => state.supportticket.confirmBookingTTResponse

export const supportticketError = (state) => state.supportticket.error 

export default supportticketSlice.reducer;