import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../agent';

import {
  isApiError,
} from '../../common/utils';

export const getPayment = createAsyncThunk(
  'paymentmethod',
  agent.Paymentmethod.get
);

export const makePrimary = createAsyncThunk(
  'paymentmethod/makePrimary',
  async (paymentId, thunkApi) => {
    try {
      const user = await agent.makePrimary.post(paymentId);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  'paymentmethod/add',
  async (paymentId, thunkApi) => {
    await agent.addPaymentMethod.post(paymentId)
  }
);

export const makePaymentMethod = createAsyncThunk(
  'paymentmethod/makePaymentMethod',
  async (paymentId, thunkApi) => {
    try {
      const user = await agent.makePaymentMethod.post(paymentId);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const makeCardPayment = createAsyncThunk(
  'paymentmethod/makeCardPayment',
  async (paymentId, thunkApi) => {
    try {
      const user = await agent.makeCardPayment.post(paymentId);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const UpdateCard = createAsyncThunk(
  'paymentmethod/UpdateCard',
  async (data, thunkApi) => {
    try {
      const card = await agent.updateCard.post(data);
      return card;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const generatePaymentSignature = createAsyncThunk(
  'paymentmethod/generatePaymentSignature',
  async (paymentId, thunkApi) => {
    try {
      const user = await agent.generatePaymentSignature.post(paymentId);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const removeCard = createAsyncThunk(
  'paymentmethod/removeCard',
  async (paymentId, thunkApi) => {
    try {
      const user = await agent.deletePaymentmethod.post(paymentId);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const primaryPaymentMethod = createAsyncThunk(
  'paymentmethod/primaryPaymentMethod',
  async ({ paymentId, newcard = false }, thunkApi) => {
    try {
      const user = await agent.primaryPaymentMethod.post({ paymentId, newcard });
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const getOutstandingBalance = createAsyncThunk(
  'paymentmethod/getOutstandingBalance',
  async (method, thunkApi) => {
    try {
      const user = await agent.getOutstandingBalance.get(method);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

const initialState = {
    paymentMethod: null,
    loaded: false,
    currentCard: null,
    paymentData: null
  };

const dashboardSlice = createSlice({
  name: 'paymentmethod',
  initialState,
  reducers: {
    PaymentPageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {

    builder.addCase(getPayment.fulfilled, (state, action) => {
        state.paymentMethod = action.payload;
        state.loaded = true;
    });

    builder.addCase(getPayment.rejected, (state, action) => {
      state.loaded = true;
    });

    builder.addCase(generatePaymentSignature.fulfilled, (state, action) => {
      state.paymentData = action.payload;
  });
    
  },
});

export const { PaymentPageUnloaded } = dashboardSlice.actions;

export default dashboardSlice.reducer;
