import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../agent';

export const getDashboard = createAsyncThunk(
  'dashboard',
  agent.Dashboard.get
);

const initialState = {
    dashboard: {},
    loaded: false
  };

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardPageUnloaded: () => ({}),
    clearDashboard: (state) => {
      state.dashboard = {}
    } 
  },
  extraReducers: (builder) => {

    builder.addCase(getDashboard.fulfilled, (state, action) => {
        
        state.dashboard = { ...state.dashboard, ...action.payload };
        //state.dashboard = action.payload
        state.loaded = true;
    });

    builder.addCase(getDashboard.rejected, (state, action) => {
      state.loaded = true;
  });
    
  },
});

export const { dashboardPageUnloaded, clearDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
