import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../agent';
import {
  isApiError,
} from '../../common/utils';

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  agent.Profile.get
);

// export const updateProfile = createAsyncThunk('profile/update', agent.Profile.update);

export const verifyNewEmail = createAsyncThunk(
  'profile/verifyNewEmail',
  agent.Profile.verifyNewEmail
);

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (data, thunkApi) => {
    try {
      const user = await agent.Profile.update(data);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);


const initialState = {
    user: []
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profilePageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {
    const successCaseReducer = (_, action) => ({
      ...action.payload,
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
        state.user = action.payload;
    //   state.articlesCount = action.payload.articlesCount;
    //   state.currentPage = action.meta.arg?.page ?? 0;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
        state.msg = action.payload;
    });
    
    // builder.addCase(updateProfile.fulfilled, successCaseReducer);
  },
});

export const { profilePageUnloaded } = profileSlice.actions;

export default profileSlice.reducer;
