import * as React from 'react';
import { components } from 'react-select';
const SuperMagicLoginPage = React.lazy(() => import('./Pages/SuperMagicLogin/SuperMagicLoginPage'));
const LoginPage = React.lazy(() => import('./Pages/Login/LoginPage'));
const LogoutPage = React.lazy(() => import('./Pages/Logout/LogoutPage'));
const RegistrationPage = React.lazy(() => import('./Pages/Registration/RegistrationPage'));
const SuccessPage = React.lazy(() => import('./Pages/Success/SuccessPage'));
const VerificationPage = React.lazy(() => import('./Pages/Verification/VerificationPage'));
const VerificationTransferSuccess = React.lazy(() => import('./Pages/Verification/VerificationTransferSuccess'));
const BreadcrumbNew = React.lazy(() => import('./App/layout/AdminLayout/BreadcrumbNew/breadcrumb'));
const SpeedboostAccelerator = React.lazy(() => import('./Pages/Externals/SpeedboostAccelerator'));

//const ipparams = window.localStorage.getItem('ipparams');
const route = [
    { path: '/sg/super-magic-login/:hash', exact: true, name: 'Super Magic Login Page', component: SuperMagicLoginPage},
    { path: `/sg/login`, exact: true, name: 'Login', component: LoginPage },
    // { path: `/sg/login/:subid`, exact: true, name: 'Login', component: LoginPage },
    { path: '/sg/logout', exact: true, name: 'Logout', component: LogoutPage },
    { path: '/my/login', exact: true, name: 'Login', component: LoginPage },
    { path: '/my/logout', exact: true, name: 'Logout', component: LogoutPage },
    { path: '/sg/register/:token', exact: true, name: 'Registration', component: RegistrationPage },
    { path: '/my/register/:token', exact: true, name: 'Registration', component: RegistrationPage },
    { path: '/sg/email-verification/:id', exact: true, name: 'Success', component: SuccessPage },
    { path: '/my/email-verification/:id', exact: true, name: 'Success', component: SuccessPage },
    // { path: '/verification/', role: null, exact: true, name: 'Verification Page', component: VerificationPage },
    { path: '/sg/verify-transfer/:id', role: null, exact: true, name: 'Verification Page', component: VerificationPage },
    { path: '/my/verify-transfer/:id', role: null, exact: true, name: 'Verification Page', component: VerificationPage },
    { path: '/sg/verify-transfer', role: null, exact: true, name: 'Verification Page', component: VerificationPage },
    { path: '/my/verify-transfer', role: null, exact: true, name: 'Verification Page', component: VerificationPage },
    { path: '/sg/verify-transfer-success', role: null, exact: true, name: 'Verification Success Page', component: VerificationTransferSuccess },
    { path: '/my/verify-transfer-success', role: null, exact: true, name: 'Verification Success Page', component: VerificationTransferSuccess },
    { path: '/breadcrumb/', role: null, exact: true, name: 'Breadcrumb Page', component: BreadcrumbNew },
    { path: '/sg/speedupgrade', role: null, exact: true, name: 'XGSPON Accelerator', component: SpeedboostAccelerator }
];
export default route;
