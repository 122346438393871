import * as React from 'react';
import { useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import {OverlayLoader2 } from '../../App/components/Overlay';

import axios from 'axios';
import _ from 'lodash';

import '../../assets/scss/themes/pages/_communityforum.scss';

const DiscourseStart = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const sso = searchParams.get("sso");
  const sig = searchParams.get("sig");

  const credentials = JSON.parse(localStorage.getItem('credentials'));

  const [isMobile, setIsMobile] = React.useState(false);


  React.useEffect(() => {

    const init = async () => {
      if(_.isNull(credentials)){
        window.location.href = '/'
      } else {
        if(!_.isNull(sso) && !_.isNull(sig)) {
          await axios.post(`${process.env.REACT_APP_BACKEND_API}/forum/callback`, {
              sso: sso,
              sig: sig,
            }, {
              headers: { 
                "Authorization" : `Bearer ${credentials.access_token}`,
              }
          })
          .then(response => {
            window.location.replace(response.data.login_url)
          })
          .catch(error => {
            window.location.href = '/'
          })
        } else {
          window.location.replace(`${process.env.REACT_APP_DISCOURSE_LOGIN_URL}`);
        }
      }
    }

    init()
      
  }, [])

  React.useEffect(() => {
    if (window.innerWidth > 480) {
      if (isMobile) {
          setIsMobile(false);
      }
    } else {
      if (!isMobile) {
          setIsMobile(true);
      }
    }
}, [isMobile])

  return (<>
    <Row className='community-forum-wrapper'>
      <Col>
        <div className="content-wrapper">
          <div className='discourse-loading'>
            <p>{!_.isNull(sso) && !_.isNull(sig)? `Redirecting to Community Forum...` : `Loading...` }</p>
            <OverlayLoader2 bgColor='none' width={isMobile? 80 : 100} height={isMobile? 80 : 100} />
          </div>
        </div>
      </Col>
    </Row>
  </>);

}
export default DiscourseStart;
