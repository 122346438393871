import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../agent';

export const getInvoice = createAsyncThunk(
  'invoice/getInvoice',
  agent.Invoices.get
);

export const getQR = createAsyncThunk(
  'invoice/getQR',
  agent.getQR.get
);

const initialState = {
    // invoices: localStorage.getItem("cp_invoices") ? JSON.parse(localStorage.getItem("cp_invoices")) : [],
    invoices: [],
    qr: null
  };

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    profilePageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {

    builder.addCase(getInvoice.fulfilled, (state, action) => {
        state.invoices = action.payload;
        localStorage.setItem('cp_invoices', JSON.stringify(action.payload))
    });

    builder.addCase(getQR.fulfilled, (state, action) => {
      state.qr = action.payload.qr;
  });
    
  },
});

export const { profilePageUnloaded } = invoiceSlice.actions;

export default invoiceSlice.reducer;
