import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isApiError } from '../../common/utils';
import agent from '../agent';

export const getTickets = createAsyncThunk(
    'tickets',
    () => agent.Support.getTickets()
);

// export const newEnquiry = createAsyncThunk(
//   'new-enquiry',
//   (data) => agent.Support.newEnquiry(data)
// )

export const newEnquiry = createAsyncThunk(
  'new-enquiry',
  async (data, thunkApi) => {
    try {
      const enquiry = await agent.Support.newEnquiry(data);
      return enquiry;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

const initialState = {
    support: []
};

const supportSlice = createSlice({
  name: 'supportList',
  initialState,
  reducers: {
    supportPageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {
    const successCaseReducer = (_, action) => ({
      ...action.payload,
    });

    builder.addCase(getTickets.fulfilled, (state, action) => {
        state.tickets = action.payload;
    });
  },
});

export const { supportPageUnloaded } = supportSlice.actions;

export default supportSlice.reducer;