import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isApiError } from '../../common/utils';
import agent from '../agent';
import _ from 'lodash';

// export const subscriptionsShowLess = createAsyncThunk(
//   'subscriptionsShowLess',
//   () => {}
// );

export const fromManageSubscription = createAsyncThunk(
  'fromManageSubscription',
  () => {}
);

export const getSubscriptionsPagination = createAsyncThunk(
  'getSubscriptionsPagination',
  (data) => agent.Subscriptions.all(data)
);

export const getSubscriptions = createAsyncThunk(
  'subscriptions',
  (data) => agent.Subscriptions.all(data)
);

export const getSubscriptionDetails = createAsyncThunk(
  'subscriptionDetails',
  (data) => agent.Subscriptions.all(data)
);

export const getSubscriptionsList = createAsyncThunk(
  'subscriptions',
  () => agent.Subscriptions.list()
);

export const getVasDetails = createAsyncThunk(
  'getVasDetails',
  () => agent.getVasDetails.get()
);

export const subscribeVas = createAsyncThunk(
  'subscribeVas',
  async (serviceType, thunkApi) => {
    try {
      const user = await agent.subscribeVas.post(serviceType);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const unsubscribeVas = createAsyncThunk(
  'unsubscribeVas',
  async (serviceType, thunkApi) => {
    try {
      const user = await agent.unsubscribeVas.post(serviceType);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const postVasJob = createAsyncThunk(
  'vasJob',
  async (id, thunkApi) => {
    try {
      const response = await agent.vasJob.post(id) 
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
)

export const terminateFibernet = createAsyncThunk(
  'terminateFibernet',
  async (data, thunkApi) => {
    try {
      const user = await agent.terminateFibernet.post(data);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const updateSG = createAsyncThunk(
  'updateSG',
  async (data, thunkApi) => {
    try {
      const user = await agent.updateSG.post(data);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const updateSchedule = createAsyncThunk(
  'updateSchedule',
  async (data, thunkApi) => {
    try {
      const user = await agent.updateSchedule.post(data);
      return user;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const getSecurenet = createAsyncThunk(
  'getSecurenet',
  (Case_ID) => agent.getSecurenet.get(Case_ID)
);

export const getList = createAsyncThunk(
  'getList',
  (Case_ID) => agent.getList.get(Case_ID)
);

export const getScheduler = createAsyncThunk(
  'getScheduler',
  () => agent.getScheduler.get()
);



const initialState = {
  // subscriptions: localStorage.getItem('cp_subscriptions') ? JSON.parse(localStorage.getItem('cp_subscriptions')) : null,
  subscriptions: null,
  subscriptionDetails: [],
  loaded: false,
  loadedSecurenet: false,
  loadedSubscription: false,
  vas: null,
  securityList: null,
  securenet: null,
  scheduler: null,
  singlePage: false,
  paginationLoading: false,
  nextPage: false,
};

const subscriptionSlice = createSlice({
  name: 'subscriptionList',
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
      delete state.tag;
    },
    subscriptionsShowLess: (state, action) => {
      const subPrevCount = JSON.parse(localStorage.getItem('cp_subscription_count'))[action.payload.page];
      if (state.subscriptions.length > subPrevCount) {
        const arr = state.subscriptions
        const groups = [];
  
        for (let i = 0; i < arr.length; i += subPrevCount) {
          groups.push(arr.slice(i, i + subPrevCount));
        }

        state.subscriptions = groups.reverse().pop().map(item => item);
        localStorage.setItem('cp_subscriptions', JSON.stringify((state.subscriptions.length) ? state.subscriptions : null));
        
        let pages = JSON.parse(localStorage.getItem('cp_subscription_count'));
        let setpages = _.omit(pages, action.payload.removepage)    
        localStorage.setItem('cp_subscription_count', JSON.stringify(setpages)); 
        
        state.nextPage = true;
      }
    },
    clearSubscription: (state, action) => {
      state.subscriptions = null
    }
  },
  extraReducers: (builder) => {
    const subLimit = 20;
    const subsCount = {};
   
    // builder.addCase(subscriptionsShowLess.fulfilled, (state, action) => {
    //   const subPrevCount = JSON.parse(localStorage.getItem('cp_subscription_count'))[action.meta.arg.page];
    //   if (state.subscriptions.length > subPrevCount) {
    //     const arr = state.subscriptions
    //     const groups = [];
  
    //     for (let i = 0; i < arr.length; i += subPrevCount) {
    //       groups.push(arr.slice(i, i + subPrevCount));
    //     }

    //     state.subscriptions = groups.reverse().pop().map(item => item);
    //     localStorage.setItem('cp_subscriptions', JSON.stringify((state.subscriptions.length) ? state.subscriptions : null));
    //     state.nextPage = true;
    //   }
    // });
    builder.addCase(getSubscriptionsPagination.pending, (state, action) => {
      state.paginationLoading = true;
    });
    builder.addCase(getSubscriptionsPagination.fulfilled, (state, action) => {
      action.payload.subscriptions.map((sub, index) => {
        const exists = state.subscriptions.some(obj => obj.subscriptionNumber === sub.subscriptionNumber)
        if (!exists) {
          state.subscriptions.push(sub)
        }
      })
      state.nextPage = (action.payload.next_page === null) ? false : true;
      localStorage.setItem('cp_subscriptions', JSON.stringify((state.subscriptions && state.subscriptions.length) ? state.subscriptions : null));

      subsCount[action.meta.arg.pagination] = JSON.parse(localStorage.getItem('cp_subscription_count'))[action.meta.arg.pagination - 1] + action.payload.subscriptions.length;    
      localStorage.setItem('cp_subscription_count', JSON.stringify(subsCount)); 

      state.loadedSubscription = true;
      state.paginationLoading = false;

      //saving the next_page value 
      //saving the next_page value 
      let next_page_metadata = localStorage.getItem('next_page_metadata') ? JSON.parse(localStorage.getItem('next_page_metadata')) : {}

      if(!_.isNull(action.payload.next_page)) {
        let new_metadata = {}
        let pagination_arg = action.meta.arg.pagination 
        
        new_metadata[`p${pagination_arg+1}`] = action.payload.next_page  
        next_page_metadata = { ...next_page_metadata, ...new_metadata}

        

        localStorage.setItem('next_page_metadata', JSON.stringify(next_page_metadata));
      } 
    });
    builder.addCase(getSubscriptionsPagination.rejected, (state, action) => {
      state.paginationLoading = true;
    });

    builder.addCase(getSubscriptions.pending, (state) => {
      state.loadedSubscription = false
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {

      state.subscriptions = (action.payload.subscriptions !== null) ? action.payload.subscriptions : null;
      localStorage.setItem('cp_subscriptions', JSON.stringify((state.subscriptions && state.subscriptions.length) ? state.subscriptions : null));

      subsCount[action.meta.arg.pagination] = (!_.isEmpty(action.payload.subscriptions)) ? action.payload.subscriptions.length : 0;    
      localStorage.setItem('cp_subscription_count', JSON.stringify(subsCount)); 
      
      state.nextPage = (action.payload.next_page === null) ? false : true;

      if (!state.nextPage) {
        state.singlePage = true;
      }

      //saving the next_page value 
      let next_page_metadata = localStorage.getItem('next_page_metadata') ? JSON.parse(localStorage.getItem('next_page_metadata')) : {}


      if(!_.isNull(action.payload.next_page)) {
        let new_metadata = {}
        let pagination_arg = action.meta.arg.pagination 
        
        new_metadata[`p${pagination_arg+1}`] = action.payload.next_page  
        next_page_metadata = { ...next_page_metadata, ...new_metadata}

        localStorage.setItem('next_page_metadata', JSON.stringify(next_page_metadata));
      } 

      state.loadedSubscription = true;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.loadedSubscription = true;
      state.paginationLoading = false;
    });

    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      state.subscriptionDetails = action.payload.subscriptions;
      state.subscriptions = [];
      // localStorage.setItem('cp_subscriptions', JSON.stringify(action.payload))
      state.loaded = true;
      state.loadedSubscription = false;
    })

    builder.addCase(getVasDetails.fulfilled, (state, action) => {
      state.vas = action.payload;
      state.loaded = true;
    });

    builder.addCase(getList.fulfilled, (state, action) => {
      state.securityList = action.payload.data;
      state.loadedSecurenet = true;
    });
    // builder.addCase(getList.rejected, (state, action) => {
    //   state.loadedSecurenet = true;
    // });

    builder.addCase(getSecurenet.fulfilled, (state, action) => {
      state.securenet = action.payload;
    });

    builder.addCase(getScheduler.fulfilled, (state, action) => {
      state.scheduler = action.payload;
    });
    
    builder.addCase(fromManageSubscription.fulfilled, (state, action) => {
      if (localStorage.getItem('cp_subscriptions')) {
        state.subscriptions = JSON.parse(localStorage.getItem('cp_subscriptions'));

        if (state.subscriptions.length > subLimit) {
          state.singlePage = false;
        }
      }
      state.loadedSubscription = true;
    });
  },
});

export const { homePageUnloaded } = subscriptionSlice.actions;
export const { subscriptionsShowLess, clearSubscription } = subscriptionSlice.actions;


export default subscriptionSlice.reducer;

export const subRelocation = createAsyncThunk(
  'subscription/relocation',
  async (data, thunkApi) => {
    try {
      const subscription = await agent.subscriptionRelocation.update(data);
      return subscription;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const subTempSuspension = createAsyncThunk(
  'subscription/temporary-suspension',
  async (data, thunkApi) => {
    try {
      const subscription = await agent.subscriptionTemporarySuspension.update(data);
      return subscription;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const subTransferOwnership = createAsyncThunk(
  'subscription/transfer-ownership',
  async (data, thunkApi) => {
    try {
      const subscription = await agent.subscriptionTransferOfOwnership.update(data);
      return subscription;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

export const verifyTransferToken = createAsyncThunk(
  'subscription/verifyTransferToken', async (data, thunkApi) => {
    try {
      const response = await agent.subscriptionTransferOfOwnership.verifyToken(data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

export const verifyTransfer = createAsyncThunk(
  'subscription/verifyTransferOwnership',
  async (data, thunkApi) => {
    try {
      const transfer = await agent.subscriptionTransferOfOwnership.verify(data);
      return transfer;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

export const getMyInfo = createAsyncThunk(
  'get-myinfo',
  async (data, thunkApi) => {
    try {
      const myInfo = await agent.myInfo.getMyInfo(data);
      return myInfo;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

// export const verifyTransfer = createAsyncThunk(
//   'subscriptions/verifyTransferOwnership',
//   data => agent.subscriptionTransferOfOwnership.verify(data)
// );

// export const getMyInfo = createAsyncThunk(
//   'get-myinfo',
//   async data => {
//     const update = await agent.myInfo.getMyInfo(data);
//     return update;
//   }
// );