import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isApiError } from '../../common/utils';

import agent from '../agent';
// import { articlePageUnloaded, createArticle, updateArticle } from './article';
// import { profilePageUnloaded } from './profile';
import { homePageUnloaded } from './subscription';
import {
  getUser,
  getMenu,
  magicLogin,
  logout,
  register,
  setToken,
  updateUser,
} from '../../Pages/Login/LoginSlice';

export const appLoad = (token) => (dispatch) => {
  dispatch(commonSlice.actions.loadApp());
  if (token) {
    agent.setToken(token);
    dispatch(setToken(token));
    dispatch(getMenu());
    return dispatch(getUser());
  }
};

const initialState = {
  appName: 'ViewQwest',
  appLoaded: false,
  tokenVerified: false,
  viewChangeCounter: 0,
  redirectTo: undefined,
  user: null,
  menu: [],
  tokenIsValid: null
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    loadApp(state) {
      state.appLoaded = true;
    },
    clearRedirect(state) {
      delete state.redirectTo;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.redirectTo = '/';
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload
      state.tokenIsValid = true
      state.tokenVerified = true
    });

    builder.addCase(getMenu.fulfilled, (state, action) => {
      state.menu = action.payload
      state.tokenIsValid = true
      state.tokenVerified = true
    });

    builder.addCase(getUser.rejected, (state, action) => {
      state.tokenIsValid = false
      state.tokenVerified = true
    });

    // builder.addCase(getUser.rejected, (state, action) => {
    //   state.redirectTo = "/login";
    // });

    builder.addCase(magicLogin.fulfilled, (state, action) => {
        state.redirectTo = '/';
    });

    builder.addCase(register.fulfilled, (state, action) => {
      state.redirectTo = '/';
    });

    builder.addCase(logout, (state) => {
      state.redirectTo = '/';
    });

    // builder.addCase(createArticle.fulfilled, (state, action) => {
    //   state.redirectTo = `/article/${action.payload.article.slug}`;
    // });

    // builder.addCase(updateArticle.fulfilled, (state, action) => {
    //   state.redirectTo = `/article/${action.payload.article.slug}`;
    // });

    builder.addMatcher(
      (action) =>
        [
          homePageUnloaded.type,
        ].includes(action.type),
      (state) => {
        state.viewChangeCounter++;
      }
    );
  },
});

export const { clearRedirect } = commonSlice.actions;

export const selectUser = (state) => state.user;

export const getPostalCode = createAsyncThunk(
  'getPostalCode',
  async (data, thunkApi) => {
    try {
      const response = await agent.SGLocate.get(data);
      return response;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }
      throw error;
    }
  }
);

export default commonSlice.reducer;
