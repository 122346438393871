import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

import ableReducer from './ableReducer';
import demoReducer from './demoReducer';
import authReducer from '../Pages/Login/LoginSlice';
import commonReducer from './reducers/common';
import subscriptionReducer from './reducers/subscription';
import profileReducer from './reducers/profile';
import invoiceReducer from './reducers/invoice';
import dashboardReducer from './reducers/dashboard';
import paymentReducer from './reducers/payment';
import supportReducer from './reducers/support';
import { localStorageMiddleware } from './middleware';
import speedboostpromoReducer from './reducers/speedboostpromo';
import discourseReducer from './reducers/discourse';
import pendingsubscriptionReducer from './reducers/pendingsubscription';
import supportticketReducer from './reducers/supportticket';


// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, pendingsubscriptionReducer);

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      auth: authReducer,
      able: ableReducer,
      demo: demoReducer,
      common: commonReducer,
      subscription: subscriptionReducer,
      profile: profileReducer,
      invoice: invoiceReducer,
      dashboard: dashboardReducer,
      payment: paymentReducer,
      support: supportReducer,
      speedboostpromo: speedboostpromoReducer,
      discourse: discourseReducer,
      pendingsubscriptions: pendingsubscriptionReducer,
      supportticket: supportticketReducer,
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      localStorageMiddleware,
    ],
  });
}

const store = makeStore();

export default store;
