import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../agent';
import { isApiError } from '../../common/utils';
import _ from 'lodash';
import axios from 'axios';

export const getPendingSubscriptions = createAsyncThunk( "pendingSubscriptions/getPendingSubscriptions",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.get('/subscriptions/get-pending-sub', data);
      return response;
    } catch (error) {
      //if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      //}
      //throw error;
    }
  }
);

export const confirmBooking = createAsyncThunk( "pendingSubscriptions/confirmBooking",
  async (data, thunkApi) => {
    try {
      const response = await agent.requests.post(
        '/subscriptions/confirm-booking', data);
      return response;
    } catch (error) {
      // if (isApiError(error)) {
      return thunkApi.rejectWithValue(error);
      // }
      //throw error;
    }
  }
);

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  getPendingSubscriptionsResponse: {},
  confirmBookingResponse: {},
}

const pendingsubscriptionSSlice = createSlice({
  name: 'pendingsubscriptions',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle"
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    // getPendingSubscription
    builder
    .addCase(getPendingSubscriptions.pending, (state) => {
      state.status = "getPendingSubscriptions/loading";
    })
    .addCase(getPendingSubscriptions.fulfilled, (state, action) => {
      state.status = "getPendingSubscriptions/success";
      state.getPendingSubscriptionsResponse = action.payload;
    })
    .addCase(getPendingSubscriptions.rejected, (state, action) => {
      state.status = "getPendingSubscriptions/failed";
      state.error = action.payload;
    });

    // confirmBooking
    builder
    .addCase(confirmBooking.pending, (state) => {
      state.status = "confirmBooking/loading";
    })
    .addCase(confirmBooking.fulfilled, (state, action) => {
      state.status = "confirmBooking/success";
      state.confirmBookingResponse = action.payload;
    })
    .addCase(confirmBooking.rejected, (state, action) => {
      state.status = "confirmBooking/failed";
      state.error = action.payload;
    });
  }
})

export const { resetStatus } = pendingsubscriptionSSlice.actions;

export const loadingPendingSubscriptionsStatus = (state) => state.pendingsubscriptions.status

export const getPendingSubscriptionsResponse = (state) => state.pendingsubscriptions.getPendingSubscriptionsResponse
export const getconfirmBookingResponse = (state) => state.pendingsubscriptions.confirmBookingResponse
// export const getOrderSummaryResponse = (state) => state.speedboostpromo.orderSummaryResponse
// export const getSubmitSelectedPlanResponse = (state) => state.speedboostpromo.submitSelectedPlanResponse
export const PendingSubscriptionstError = (state) => state.pendingsubscriptions.error 

export default pendingsubscriptionSSlice.reducer;