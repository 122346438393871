import {
    createAsyncThunk,
    createSelector,
    createSlice,
  } from '@reduxjs/toolkit';
  
  import agent from '../../store/agent';
  import {
    failureReducer,
    isApiError,
    loadingReducer,
    Status,
  } from '../../common/utils';
  
  /**
   * @typedef {object} User
   * @property {string} email
   * @property {string} account_no
   * @property {string} location
   * @property {string} type
   * 
   *
   *
   * @typedef {object} AuthState
   * @property {Status} status
   * @property {string} token
   * @property {User}   user
   * @property {Record<string, string[]>} errors
   */
  
  /**
   * Send a register request
   *
   * @param {object} argument
   * @param {string} argument.account_no
   * @param {string} argument.email
   * @param {string} argument.password
   */
  export const register = createAsyncThunk(
    'auth/register',
    async ({ account_no, email, password }, thunkApi) => {
      try {
        const {
          user: { token, ...user },
        } = await agent.Auth.register(account_no, email, password);
  
        return { token, user };
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
  
        throw error;
      }
    },
    {
      condition: (_, { getState }) => !selectIsLoading(getState()),
    }
  );
  
  /**
   * Send a login request
   *
   * @param {object} argument
   * @param {string} argument.email
   * @param {string} argument.account_no
   * @param {string} argument.location
   * @param {string} argument.type
   */
  export const login = createAsyncThunk(
    'magic-link',
    async ({ account_no, email, location, type }, thunkApi) => {
      try {
        const user = await agent.Auth.login(account_no, email, location, type);
        return user;
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
        throw error;
      }
    },
    // {
    //   condition: (_, { getState }) => !selectIsLoading(getState()),
    // }
  );

  export const postFeedback = createAsyncThunk(
    'logout/feedback',
    async (data, thunkApi) => {
      try {
        const feedback = await agent.Auth.postFeedback(data);
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
  
        throw error;
      }
    },
    // {
    //   condition: (_, { getState }) => !selectIsLoading(getState()),
    // }
  );
  
  /**
   * validate user token
   */
  export const magicLogin = createAsyncThunk(
    'auth/magic-login',
    async (query) => {
      const user = await agent.Auth.magicLogin(query);
      return user;
    },
    // {
    //   condition: (_, { getState }) => !selectIsLoading(getState()),
    // }
  );

  /**
   * validate user token
   */

  export const singpassLogin = createAsyncThunk(
    '/auth/singpass-login',
    async (data, thunkApi) => {
      try {
        const user = await agent.Auth.singpassLogin(data);
        return user ;
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
        throw error;
      }
    }
  );

  
/**
 * Send a get current user request
 */
export const getUser = createAsyncThunk(
  'auth/userDetails',
  async () => {
    const user = await agent.Auth.current();
    return user;
  },
  {
    condition: (_, { getState }) => Boolean(selectAuthSlice(getState()).token),
  }
);

/**
 * Send a get dynamicmenue request
 */
export const getMenu = createAsyncThunk(
  'auth/dynamic-menu',
  async () => {
    const menu = await agent.Auth.getMenu();
    return menu;
  },
  {
    condition: (_, { getState }) => Boolean(selectAuthSlice(getState()).token),
  }
);

export const ipLocate = createAsyncThunk(
  'auth/ip-locate',
  async () => {
    const iplocate = await agent.Auth.ipLocate();
    return iplocate;
  }
);
  
  /**
   * Send a update user request
   *
   * @param {object} argument
   * @param {string} argument.email
   * @param {string} argument.account_no
   * @param {string} argument.bio
   * @param {string} argument.image
   * @param {string} argument.password
   */
  export const updateUser = createAsyncThunk(
    'auth/updateUser',
    async ({ email, account_no, bio, image, password }, thunkApi) => {
      try {
        const {
          user: { token, ...user },
        } = await agent.Auth.save({ email, account_no, bio, image, password });
  
        return { token, user };
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
  
        throw error;
      }
    },
    {
      condition: (_, { getState }) =>
        selectIsAuthenticated(getState()) && !selectIsLoading(getState()),
    }
  );

  export const registerUser = createAsyncThunk(
    '/register',
    async (data, thunkApi) => {
      try {
        const user = await agent.Auth.registerUser(data);
  
        return user ;
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
        throw error;
      }
    }
  );

  export const selfScheduleLogin = createAsyncThunk( 'auth/self-schedule-login',
    async (data, thunkApi) => {
      try {
        const response = await agent.requests.get('/check-case-id', data);
        return response;
      } catch (error) {
        //if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        //}
        //throw error;
      }
    }
  );
  
  const initialState = {
    status: Status.IDLE,
    isLocated: false
  };
  function successReducer(state, action) {
    state.status = Status.SUCCESS;
    if (action.payload.access_token) {
      state.token = action.payload.access_token;
      state.user = action.payload;
    }
    delete state.errors;
  }
  
  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      /**
       * Log out the user
       */
      logout: () => initialState,
      /**
       * Update token
       *
       * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
       * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
       */
      setToken(state, action) {
        state.token = action.payload;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(login.fulfilled, successReducer)
        .addCase(register.fulfilled, successReducer)
        .addCase(magicLogin.fulfilled, successReducer)
        .addCase(singpassLogin.fulfilled, successReducer)
        .addCase(updateUser.fulfilled, successReducer)
        .addCase(selfScheduleLogin.fulfilled, successReducer)
      
      builder.addCase(ipLocate.fulfilled, (state, action) => {
          state.isLocated = true;
      });
  
      builder
        .addCase(login.rejected, failureReducer)
        .addCase(register.rejected, failureReducer)
        .addCase(singpassLogin.rejected, failureReducer)
        .addCase(updateUser.rejected, failureReducer)
        .addCase(selfScheduleLogin.rejected, failureReducer)
  
      builder.addMatcher(
        (action) => /auth\/.*\/pending/.test(action.type),
        loadingReducer
      );
    },
  });
  
  export const { setToken, logout } = authSlice.actions;
  
  /**
   * Get auth slice
   *
   * @param {object} state
   * @returns {AuthState}
   */
  const selectAuthSlice = (state) => state.auth;
  
  /**
   * Get current user
   *
   * @param {object} state
   * @returns {User}
   */
  export const selectUser = (state) => selectAuthSlice(state);
  
  /**
   * Get errors
   *
   * @param {object} state
   * @returns {Record<string, string[]}
   */
  export const selectErrors = (state) => selectAuthSlice(state).errors;
  
  /**
   * Get is loading
   *
   * @param {object} state
   * @returns {boolean} There are pending effects
   */
  export const selectIsLoading = (state) => {
    return selectAuthSlice(state).status === Status.LOADING;
  }
  
  /**
   * Get is authenticated
   *
   * @param {object} state
   * @returns {boolean}
   */
  export const selectIsAuthenticated = createSelector(
    (state) => selectAuthSlice(state).token,
    selectUser,
    (token, user) => Boolean(token && user)
  );

  export const superLogin = createAsyncThunk(
    'superLogin',
    async (data, thunkApi) => {
      try {
        const response = await agent.Auth.superLogin(data);
        return response;
      } catch (error) {
        if (isApiError(error)) {
          return thunkApi.rejectWithValue(error);
        }
        throw error;
      }
    }
  );
  
  export default authSlice.reducer;
  