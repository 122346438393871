import '../../assets/scss/components/spinner.scss';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/lottie/circular-loader';
const OverlayLoader = () => {
    return (<>
        <div className='vqcp-overlay full'>
            <div className='loader-container'>
                <div className='loader'></div>
            </div>
        </div>
    </>)  
}
// export default OverlayLoader;

const OverlayLoader2 = (props) => {
    const fullscreen = (props.screen === 'full');

    const style = {
        overlay: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: fullscreen ? 'fixed' : 'static',
            top: 0,
            left: 0,
            backgroundColor: (!props.hasOwnProperty('bgColor') || props.bgColor === '') ? '#fff' : (props.bgColor === 'transparent' ? 'rgba(0, 0, 0, 0.5)' : props.bgColor),
            textAlign: 'center',
            zIndex: 9999,
        },
        msg: {
            paddingTop: 16,
            fontSize: 24,
        }
    }
    
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (<>
        <div className='vqcp-overlay2' style={ style.overlay }>
            {/* <div className='loader-container'>
                <div className='loader'></div>
                {props.msg && <div style={{ ...style.msg }}>{ props.msg }</div>}
            </div> */}
            <Lottie options={lottieOptions} width={props.width || 150} height={props.height || 150}/>
        </div>
    </>)

    // Sample 
    // <OverlayLoader2 screen='full' bgColor='transparent'/>
}

export { OverlayLoader, OverlayLoader2 }