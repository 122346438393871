/* eslint-disable default-case */
import agent from './agent';
import { magicLogin, logout, register, singpassLogin } from '../Pages/Login/LoginSlice';
import { getPendingSubscriptions } from '../store/reducers/pendingsubscription';
import { getSupportTicket } from './reducers/supportticket';
import _ from 'lodash';


const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case register.fulfilled.type:
    case singpassLogin.fulfilled.type:
      if (action.payload.access_token) {
        window.localStorage.setItem('jwt', action.payload.access_token);
        window.localStorage.setItem('permissions', JSON.stringify(action.payload.permissions));
        agent.setToken(action.payload.access_token);
      }
      break;
    case magicLogin.fulfilled.type:
      if (action.payload.access_token) {
        window.localStorage.setItem('jwt', action.payload.access_token);
        window.localStorage.setItem('permissions', JSON.stringify(action.payload.permissions));
        agent.setToken(action.payload.access_token);
      }
      break;
    case logout.type:
      // window.localStorage.removeItem('jwt');
      
      localStorage.clear();
      sessionStorage.clear();

      agent.setToken(undefined);
      break;
    case getPendingSubscriptions.fulfilled.type:
    
    let curr_subs = !_.isUndefined(localStorage.getItem('pending_subs')) ? JSON.parse(localStorage.getItem('pending_subs')) : []
    // let save_subs = [];

    if(!_.isNull(curr_subs)){
      if(!_.isEmpty(action.payload.subscriptions)){
        action.payload.subscriptions.forEach(sub => {
        if(!_.some(curr_subs, ['case_id', sub.case_id])){
            curr_subs.push(sub)
          }
        })
      }
    } else {
      curr_subs = action.payload.subscriptions
    }

    window.localStorage.setItem('pending_subs', JSON.stringify(curr_subs));
    window.localStorage.setItem('pending-type', JSON.stringify(getPendingSubscriptions.fulfilled.type));
      // agent.setToken(undefined);
    break;
    case getSupportTicket.fulfilled.type:
    
      let curr_tix = !_.isUndefined(localStorage.getItem('support_tix')) ? JSON.parse(localStorage.getItem('support_tix')) : []
      // let save_subs = [];

      if(!_.isNull(curr_tix)){
        if(!_.isEmpty(action.payload.trouble_tickets)){
          action.payload.trouble_tickets.forEach(tt => {
          if(!_.some(curr_tix, ['case_id', tt.case_id])){
            curr_tix.push(tt)
            }
          })
        }
      } else {
        curr_tix = action.payload.trouble_tickets
      }

      window.localStorage.setItem('support_tix', JSON.stringify(curr_tix));
      window.localStorage.setItem('support_tix-type', JSON.stringify(getPendingSubscriptions.fulfilled.type));
        // agent.setToken(undefined);
      break;
  }

  return next(action);
};

export { localStorageMiddleware };
